const getNamespacedTypes = (namespace, { getters, mutations, actions }) => {
  const prefix = `${namespace}/`;

  const addPrefix = (obj = {}) => {
    if (!obj || typeof obj !== 'object') {
      return {};
    }

    const privateTypes = Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = `${prefix}${value}`;
      return acc;
    }, {});
    return privateTypes || {};
  };

  return Object.assign(
    {},
    getters ? { getters: addPrefix(getters) } : null,
    mutations ? { mutations: addPrefix(mutations) } : null,
    actions ? { actions: addPrefix(actions) } : null,
  );
};

// AuthStore
export const AUTH_STORE_NAMESPACE = 'auth';
export const authPrivateTypes = {
  getters: {
    IS_LOGGED_IN: 'isLoggedIn',
    GET_ACCESS_TOKEN: 'getAccessToken',
    GET_REFRESH_TOKEN: 'getRefreshToken',
    GET_LOCKED_LOGIN_INFO: 'getLockedLoginInfo',
    GET_AUTH_HAS_TIMEDOUT: 'getAuthHasTimedout',
    GET_CLIENT_REFERENCE: 'getClientReference',
  },
  mutations: {
    SET_AUTH_DATA: 'setAuthData',
    SET_ACCESS_TOKEN: 'setAccessToken',
    SET_LOCKED_LOGIN_DATA: 'setLockedLoginData',
    SET_AUTH_HAS_TIMEDOUT: 'setAuthHasTimedout',
    LOAD_STORED_AUTH_TOKENS: 'loadStoredAuthTokens',
    SET_CLIENT_REFERENCE: 'setClientReference',
    SET_USER_JUST_LOGGED_IN: 'setUserJustLoggedIn',
  },
  actions: {
    SAVE_AUTH_DATA: 'saveAuthData',
    SAVE_ACCESS_TOKEN: 'saveAccessToken',
    SAVE_LOCKED_LOGIN_DATA: 'saveLockedLoginData',
    RESET_LOCKED_LOGIN_DATA: 'resetLockedLoginData',

    LOGIN: 'login',
    LOGOUT: 'logout',
    REFRESH_TOKENS: 'refreshTokens',
    AUTH_TIMEDOUT: 'authTimedout',

    RECOVER_PASSWORD: 'recoverPassword',
    RESET_PASSWORD: 'resetPassword',
    REQUEST_ACCOUNT_DELETE: 'requestAccountDelete',
    CONFIRM_ACCOUNT_DELETE: 'confirmAccountDelete',
  },
};
export const authStoreTypes = getNamespacedTypes(AUTH_STORE_NAMESPACE, authPrivateTypes);

// I18nStore
export const I18N_STORE_NAMESPACE = 'i18n';
export const i18nPrivateTypes = {
  getters: {
    GET_LANGUAGE: 'getLanguage',
    GET_AVAILABLE_LANGUAGES: 'getAvailableLanguages',
    GET_LANGUAGE_FROM_QUERY_PARAM: 'getLanguageFromQueryParam',
    GET_LANGUAGE_FROM_USER_SELECTION: 'getLanguageFromUserSelection',
  },
  mutations: {
    SET_LANGUAGE: 'setLanguage',
    SET_LANGUAGE_FROM_QUERY_PARAM: 'setLanguageFromQueryParam',
    SET_LANGUAGE_FROM_USER_SELECTION: 'setLanguageFromUserSelection',
  },
  actions: {
    RESET: 'resetState',
    GET_USER_LANGUAGE: 'getUserLanguage',
    SET_MEMBER_ACCOUNT_LANGUAGE: 'setMemberAccountLanguage',
    UPDATE_APP_LANGUAGE: 'updateAppLanguage',
  },
};
export const i18nStoreTypes = getNamespacedTypes(I18N_STORE_NAMESPACE, i18nPrivateTypes);

// MemberStore
export const MEMBER_STORE_NAMESPACE = 'member';
export const memberPrivateTypes = {
  getters: {
    GET_MEMBER: 'getMember',
    GET_ACCOUNT_ID: 'getAccountId',
    GET_MEMBER_LANGUAGE: 'getMemberLanguage',
    GET_FINGERPRINT: 'getFingerprint',
    IS_IMPERSONATED: 'isImpersonated',
  },
  mutations: {
    SET_ACCOUNT_ID: 'setAccountId',
    SET_MEMBER: 'setMember',
    UPDATE_MEMBER: 'updateMember',
    SET_MEMBER_IS_SSO: 'setMemberIsSSO',
  },
  actions: {
    GET_MEMBER: 'getMember',
    UPDATE_MEMBER: 'updateMember',
    RESET_MEMBER: 'resetMember',
  },
};
export const memberStoreTypes = getNamespacedTypes(MEMBER_STORE_NAMESPACE, memberPrivateTypes);

// EnrolmentStore
export const ENROLMENT_STORE_NAMESPACE = 'enrolment';
export const enrolmentPrivateTypes = {
  getters: {
    GET_ENROLMENT_UUID: 'getEnrolmentUUID',
    GET_CORRELATION_UUID: 'getCorrelationUUID',
    GET_INVITE_DATA: 'getInviteID',
    IS_RECOVERING: 'isRecovering',
    IS_RESCHEDULING: 'isRescheduling',
    GET_VERSION: 'getVersion',
    GET_EXTERNAL_INTEGRATION: 'getExternalIntegration',
    HAS_ENROLLMENT: 'hasEnrollment',
    GET_BLOOM_SKIP_CALL_FLOW: 'getBloomSkipCallFlow',
    GET_DYNAMIC_UNIT_FLOW_UUID: 'getDynamicUnitFlowUUID',
    GET_NAVIGATION_DIRECTION: 'getNavigationDirection',
    GET_SUPPORT_CHAT_ENABLED: 'getSupportChatEnabled',
  },
  mutations: {
    SET_ENROLMENT_UUID: 'setEnrolmentUUID',
    SET_CORRELATION_UUID: 'setCorrelationUUID',
    SET_INVITE_DATA: 'setInviteData',
    SET_RECOVERING: 'setRecovering',
    SET_RESCHEDULING: 'setRescheduling',
    SET_VERSION: 'setVersion',
    SET_EXTERNAL_INTEGRATION: 'setExternalIntegration',
    SET_IS_DYNAMIC_ONBOARDING: 'setIsDynamicOnboarding',
    SET_BLOOM_SKIP_CALL_FLOW: 'setBloomSkipCallFlow',
    SET_DYNAMIC_UNIT_FLOW_UUID: 'setDynamicUnitFlowUUID',
    SET_NAVIGATION_DIRECTION: 'setNavigationDirection',
    SET_SUPPORT_CHAT_ENABLED: 'setSupportChatEnabled',
  },
  actions: {
    START_ENROLMENT: 'startEnrollment',
    CREATE_ENROLLMENT: 'createEnrollment',
    LOAD_EXTERNAL_INTEGRATION: 'loadExternalIntegration',
    LOAD_BLOOM_SKIP_CALL_FLOW: 'loadBloomSkipCallFlow',
    SET_SUPPORT_CHAT_ENABLED: 'setSupportChatEnabled',
  },
};
export const enrolmentStoreTypes = getNamespacedTypes(ENROLMENT_STORE_NAMESPACE, enrolmentPrivateTypes);

// MarketingIntegrationStore
export const MARKETING_ENDPOINT_NAMESPACE = 'marketing';
export const marketingIntegrationPrivateTypes = {
  actions: {
    UNSUBSCRIBE_LEAD: 'unsubscribeLead',
  },
};

export const marketingIntegrationStoreTypes = getNamespacedTypes(MARKETING_ENDPOINT_NAMESPACE, marketingIntegrationPrivateTypes);

// TriageStore
export const TRIAGE_STORE_NAMESPACE = 'triage';
export const triagePrivateTypes = {
  getters: {
    GET_TRIAGE_UUID: 'getTriageUUID',
  },
  mutations: {
    SET_TRIAGE_UUID: 'setTriageUUID',
  },
  actions: {
    START_TRIAGE: 'startTriage',
    FINISH_TRIAGE: 'finishTriage',
  },
};
export const triageStoreTypes = getNamespacedTypes(TRIAGE_STORE_NAMESPACE, triagePrivateTypes);

// DynamicFormStore
export const DYNAMIC_FORM_STORE_NAMESPACE = 'dynamicForm';
export const dynamicFormPrivateTypes = {
  getters: {
    GET_FORM_KEY: 'getFormKey',
    GET_FORM_NAME: 'getFormName',
    GET_FORM_PAGES: 'getFormPages',
    GET_FORM_ANSWERS: 'getFormAnswers',
    GET_ANSWERS_WITHOUT_ERRORS: 'getAnswersWithoutErros',
    GET_FORM_VERSION: 'getFormVersion',
    GET_FORM_PAGE_BY_INDEX: 'getPageByIndex',
    GET_CURRENT_PAGE_INDEX: 'getCurrentPageIndex',
    GET_FORM_FILTERS: 'getFormFilters',
    GET_FORM_PREVIOUS_OUTCOMES: 'getFormPreviousOutcomes',
  },
  mutations: {
    RESET_CLINICAL_FORM: 'resetClinicalForms',
    SET_CURRENT_PAGE_INDEX: 'setCurrentPageIndex',
    SET_FORM_KEY: 'setFormKey',
    SET_FORM_NAME: 'setFormName',
    SET_FORM_ANSWERS: 'setAnswers',
    SET_ANSWERS_WITHOUT_ERRORS: 'setAnswersWithoutErrors',
    RESET_FORM_ANSWERS: 'resetAnswers',
    SET_FORM_PAGES: 'setPages',
    SET_FORM_VERSION: 'setVersion',
    SET_FORM_FILTERS: 'setFormFilters',
    SET_FORM_PREVIOUS_OUTCOMES: 'setFormPreviousOutcomes',
  },
  actions: {
    SET_ANSWERS_FOR_PAGE: 'setAnswersForPage',
    SETUP_FORM: 'setupForm',
    PREFILL_ANSWERS: 'prefillAnswers',
  },
};
export const dynamicFormStoreTypes = getNamespacedTypes(DYNAMIC_FORM_STORE_NAMESPACE, dynamicFormPrivateTypes);

// ClientStore
export const CLIENT_STORE_NAMESPACE = 'client';
export const clientPrivateTypes = {
  getters: {
    GET_CLIENT_INFO: 'getClientInfo',
    GET_CLIENT_IDENTIFIERS: 'getClientIdentifiers',
    GET_CLIENT_CONFIGURATION: 'getClientConfiguration',
    GET_CLIENT_TOGGLES: 'getClientToggles',
    GET_COUNTRY: 'getCountry',
    GET_CLIENT_LANGUAGE: 'getClientLanguage',
    GET_CLIENT_AVAILABLE_LANGUAGES: 'getClientAvailableLanguages',
    GET_CLIENT_CONDITIONS: 'getClientConditions',
    GET_GEO_STATES_LIST: 'getGeoStatesList',
    GET_REFERRAL_CONFIGS: 'getReferralConfigs',
    GET_DEFAULT_LEGAL_LINKS: 'getDefaultLegalLinks',
    GET_DEFAULT_LEGAL_LINKS_BY_LANGUAGE: 'getDefaultLegalLinksByLanguage',
    GET_CLIENT_LEGAL_LINKS: 'getClientLegalLinks',
    GET_CLIENT_ELIGIBILITY_CONFIGS: 'getClientEligibilityConfigs',
    GET_CLIENT_CALL_BUFFER: 'getClientCallBuffer',
    GET_CLIENT_NUMBER_DAYS_TO_SCHEDULE: 'getClientNumberDaysToSchedule',
    GET_MININUM_SCHEDULING_DISTANCE_HOURS: 'getMininumSchedulingDistanceHours',
  },
  mutations: {
    RESET_STATE: 'resetState',
    SET_CLIENT_IDENTIFIERS: 'setClientIdentifiers',
    SET_CLIENT: 'setClient',
    SET_CLIENT_CONFIGURATION: 'setClientConfiguration',
  },
  actions: {
    SETUP_CLIENT: 'setupClient',
    FETCH_CLIENT: 'fetchClient',
    UPDATE_CLIENT_CONFIGURATION: 'updateClientConfiguration',
  },
};
export const clientStoreTypes = getNamespacedTypes(CLIENT_STORE_NAMESPACE, clientPrivateTypes);

// InvitationStore
export const INVITATION_STORE_NAMESPACE = 'invitation';

// ApplicationStore
export const APPLICATION_STORE_NAMESPACE = 'application';
export const applicationPrivateTypes = {
  getters: {
    UNIT: 'getUnit',
    IS_REFERRAL_BANNER_CLOSED: 'isReferralBannerClosed',
    IS_BLOOM: 'isBloom',
    IS_SWORD: 'isSword',
    IS_MOVE: 'isMove',
    IS_DPT_GO: 'isDptGo',
    IS_MIND: 'isMind',
    UNIT_NAME: 'getUnitName',
    SUPPORT_EMAIL_BY_UNIT: 'getSupportEmaiByUnit',
    URLS: 'URLS',
  },
  mutations: {
    SET_UNIT: 'setUnit',
    SET_DEVICE_SOURCE: 'setDeviceSource',
    SET_IS_REFERRAL_BANNER_CLOSED: 'setIsReferralBannerClosed',
    SET_INTERSTITIAL_DATA: 'setInterstitialData',
  },
  actions: {
    SET_UNIT: 'setUnit',
    SET_DEVICE_SOURCE: 'setDeviceSource',
  },
};
export const applicationStoreTypes = getNamespacedTypes(APPLICATION_STORE_NAMESPACE, applicationPrivateTypes);

// OneQuestionPerScreenStore
export const ONE_QUESTION_PER_SCREEN_STORE_NAMESPACE = 'oneQuestionPerScreen';
export const oneQuestionPerScreenPrivateTypes = {
  getters: {
    GET_LAST_SUBMITTED_PAGES: 'getLastSubmittedPages',
  },
  mutations: {
    UPDATE_LAST_SUBMITTED_PAGES: 'updateLastSubmittedPages',
    SET_LAST_SUBMITTED_PAGES: 'setLastSubmittedPages',
  },
  actions: {
    UPDATE_LAST_SUBMITTED_PAGES: 'updateLastSubmittedPages',
    RESET_LAST_SUBMITTED_PAGES: 'resetLastSubmittedPages',
  },
};
export const oneQuestionPerScreenStoreTypes = getNamespacedTypes(ONE_QUESTION_PER_SCREEN_STORE_NAMESPACE, oneQuestionPerScreenPrivateTypes);
