import Vue from 'vue';
import apiInterface from '@/scripts/api-interface';
import { appointmentStatus } from '@/definitions/appointments';

const defaultAppointmentState = () => {
  const scheduleRetroCompatibleApiData = {
    time: '',
    timezone: '',
    date: '',
    dateWithOffset: '',
    therapistSelectedDate: '',
  };

  return {
    ptFirstname: '',
    availableSlots: [],
    retrying: false,
    callType: '',
    schedule: {
      selectedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selectedDate: '',
      selectedTime: '',
      keepPT: true,
      ...scheduleRetroCompatibleApiData,
    },
  };
};

export default {
  namespaced: true,
  state: defaultAppointmentState(),
  getters: {
    getPTFirstname: state => state.ptFirstname,
    getAvailableSlots: state => state.availableSlots,
    getScheduleData: state => prop => (prop ? state.schedule[prop] : state.schedule),
    getCallType: state => state.callType,
    appointmentIsLoaded: state => {
      const hasSlots = state.availableSlots && state.availableSlots.length;
      const { schedule } = state;
      const hasSchedule = schedule && schedule.dateWithOffset;
      return hasSlots && hasSchedule;
    },
    isRetrying: state => state.retrying,
  },
  mutations: {
    setScheduleData(state, payload) {
      Object.keys(payload).forEach(prop => {
        state.schedule[prop] = payload[prop];
      });
    },
    setAvailableSlots(state, slots) {
      state.availableSlots = slots;
    },
    setPTFirstname(state, ptFirstname) {
      state.ptFirstname = ptFirstname;
    },
    setKeepPT(state, keepPT) {
      state.keepPT = keepPT;
    },
    setAsRetrying(state, retrying) {
      state.retrying = retrying;
    },
    setCallType(state, callType) {
      state.callType = callType;
    },
    resetSlots(state) {
      state.availableSlots = defaultAppointmentState().availableSlots;
    },
    resetSchedule(state) {
      state.schedule = defaultAppointmentState().schedule;
    },
  },
  actions: {
    async setupAppointment({ commit, dispatch }, params) {
      const appointment = await dispatch('fetchAppointment', params);
      const {
        client, slots, status, schedule, unit, ptFirstname, callType,
      } = appointment;

      client.unit = unit;
      client.image_url = client.client_image_url;
      commit('client/setClient', client, { root: true });
      commit('setAvailableSlots', slots);
      commit('setPTFirstname', ptFirstname);
      commit('setCallType', callType);

      if (status === appointmentStatus.CONFIRMED) {
        commit('reschedule/setInitialScheduleData', schedule, { root: true });
      }

      return appointment;
    },
    async fetchAppointment(context, params) {
      const { data } = await Vue.$http('appointment/fetchAppointmentData', params, {
        config: {
          headers: {
            'x-redirect-url': `${window.location.pathname}${window.location.search}`,
          },
        },
      });
      return apiInterface.parseAppointmentData(data);
    },
    resetSlotSelection({ commit }) {
      const defaultSchedule = defaultAppointmentState().schedule;
      commit('setScheduleData', defaultSchedule);
    },
    async updateAvailableSlots({ commit, dispatch }, params) {
      const { slots } = await dispatch('fetchAppointment', params);
      commit('setAvailableSlots', slots);
    },
    async fetchAppointmentPT(context, params) {
      const { data } = await Vue.$http('appointment/fetchAppointmentPTData', params);
      return data.available_therapists;
    },
  },
};
